<template>
  <div>
    <UserInfo />
    <div class="container-datosDeRegistro">
      <h1 class="title-consultorioMovil">Datos de registro Jornada Clínica</h1>
      <el-form
        :model="FormProject"
        :rules="rules"
        status-icon
        ref="FormProject"
        label-width="120px"
        class="demo-FormProject"
        label-position="top"
      >
      <el-row :gutter="24" class="row-consultorioMovil" justify="space-between">
        <el-col
          :xs="24"
          :sm="24"
          :md="18"
          :lg="4"
        >
          <el-form-item label="Nombre" prop="firstName">
            <el-input
              v-model="FormProject.firstName"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="Apellido" prop="lastName">
            <el-input
              v-model="FormProject.lastName"
              :disabled="!FormProject.firstName"
            />
          </el-form-item>
          <el-form-item
            label="Fecha de nacimiento"
            prop="birthdate"
          >
            <el-date-picker
              type="date"
              value-format="yyyy-MM-dd"
              @change="edadPermitida()"
              placeholder="Seleccione una date"
              v-model="FormProject.birthdate"
              style="width: 100%;"
              :disabled="!FormProject.lastName"
            />
          </el-form-item>
            <div v-if="isNew || isPatient">
              <el-form-item label="Sexo" prop="sex">
                <el-select
                  filterable
                  v-model="FormProject.sex"
                  clearable
                  placeholder="Seleccionar">
                <el-option
                  v-for="item in sexos"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
              </el-form-item>
              <el-form-item label="Padecimientos" prop="padecimientos">
                <el-select
                  multiple
                  filterable
                  v-model="FormProject.padecimientos"
                  @change="handlePadecimientos()"
                  clearable
                  placeholder="Seleccionar">
                <el-option
                  v-for="(item, idx) in padecimientosOptions"
                  :key="idx"
                  :label="item.label"
                  :value="item.value"
                />
                </el-select>
              </el-form-item>
              <el-form-item label="Teléfono" prop="phone">
                <el-input  v-model="FormProject.phone" />
              </el-form-item>
              <el-form-item label="Código postal" prop="zipCode">
                <el-input v-model="FormProject.zipCode" />
              </el-form-item>
              <el-form-item label="Alianza" prop="alianza">
                <el-select
                  filterable
                  v-model="FormProject.alianzaId"
                  clearable
                  placeholder="Seleccionar">
                <el-option
                  v-for="item in alianzas"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"  />
                </el-select>
              </el-form-item>
              <el-form-item label="Comentarios" prop="comentarios">
                <el-input type="textarea" v-model="FormProject.comentarios" />
              </el-form-item>
              <el-form-item>
                <el-button id="registrar-button" @click="submitForm('FormProject')">
                  Registrar
                </el-button>
              </el-form-item>
            </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="18"
          :lg="18"
          class="pickerDate">
          <el-input
            type="text"
            style="margin-top: 28px;"
            placeholder="Buscar paciente" clearable
            v-model="searchPatient"
          />
          <div class="option-jornadaclinica">
            <div>
              <el-button
                icon="el-icon-printer" @click="print()"
                circle
              />
              <span
              @click="print()"
              style="margin-left:5px;font-weight:bold;cursor: pointer;">Imprimir</span>
            </div>
          </div>
          <el-table
            :data="tableData"
            @row-click="clickCell"
            style="width: 100%"
          >
            <el-table-column
              prop="patientId"
              label="Expediente"
              width="150"
              sortable/>
            <el-table-column
              prop="firstName"
              label="Nombre"
              width="180"/>
            <el-table-column
              prop="lastName"
              label="Apellido"
              width="180"/>
            <el-table-column
              prop="birthdate"
              label="Nacimiento"
              width="110"/>
            <el-table-column
              prop="alianza"
              label="Alianza"
              width="180"/>
            <el-table-column
              prop="phone"
              label="Teléfono"
              width="120"/>
            <el-table-column
              prop="comentarios"
              label="Comentarios"
            />
            <el-table-column
              prop="referido"
              label="Referido">
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.referido === 'Referido' ? 'success' : 'danger'"
                  disable-transitions>{{scope.row.referido}}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="atendido"
              label="Atendidos">
              <template slot-scope="scope">
                <el-tag
                  :type="scope.row.atendido === 'Atendido' ? 'success' : 'danger'"
                  disable-transitions>{{scope.row.atendido}}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      </el-form>
    </div>
    <el-dialog
      title="Paciente registrado"
      :visible.sync="dialogIsPatient"
      width="50%"
    >
      <span>El ciudadano ya es paciente de fundación +Luz, desea seguir con el registro?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="seguirConRegistro(false)">Cancelar</el-button>
        <el-button type="primary" @click="seguirConRegistro(true)">
          Seguir con el registro
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="INFORMACIÓN DEL PACIENTE"
      :visible.sync="dialogPatient"
      @close="clearAll()"
      width="50%"
      >
        <h2>
          {{ patientSelected.firstName }}
          {{ patientSelected.lastName }} - ({{ patientSelected.patientId }})
        </h2><br>
        <div style="display: flex; gap: 15px;">
          <div>
            <strong>Tipo de cita</strong><br>
            <el-select
              size="mini"
              filterable
              v-model="tipoDeCita"
              clearable
              placeholder="Seleccionar">
              <el-option
                v-for="item in tipoCitaOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <!-- Especialidades -->
          <div v-if="tipoDeCita === 1" style="display: flex; gap: 15px">
            <div>
              <strong>Especialidad {{ especialidadNombre }}</strong><br>
              <el-select
                size="mini"
                filterable
                v-model="especialidad"
                @change="mostrarProcedimientos()"
                clearable
                placeholder="Seleccionar">
                <el-option
                  v-for="item in especialidades"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <!-- Procedimientos -->
            <div v-if="especialidad !== null">
              <strong>Procedimiento</strong><br>
              <el-select
                v-model="procedimiento"
                placeholder="Seleccionar Procedimiento"
                size="mini"
              >
                <el-option
                  v-for="item in options[especialidadNombre]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <!-- Trabajo Social -->
          <div v-if="tipoDeCita === 2">
            <strong>Trabajador social</strong><br>
            <el-select
              size="mini"
              filterable
              v-model="trabajadorSocial"
              clearable
              @change="mostrarCalendario()"
              placeholder="Seleccionar">
              <el-option
                v-for="item in trabajadoresSociales"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <Calendar
          v-if="tipoDeCita === 1 && procedimiento !== null"
          v-on:selected-hour="selectedHourJornadaClinica"
          v-on:selected-date="selectedDateJornadaClinica"
          :disabledsEspeciality="reservedHours"
        />
        <Calendar
          v-if="tipoDeCita === 2 && trabajadorSocial !== null"
          v-on:selected-hour="selectedHourJornadaClinica"
          v-on:selected-date="selectedDateJornadaClinica"
          :disableds="reservedHours"
        />
        <Calendar
          v-if="tipoDeCita === 3"
          v-on:selected-hour="selectedHourJornadaClinica"
          v-on:selected-date="selectedDateJornadaClinica"
          :disableds="reservedHours"
        />
        <div style="display: flex; gap: 15px; margin-top: 15px; justify-content: space-between;">
          <div>
            <strong>¿Fue atendido?</strong><br>
            <el-select
              v-model="patientSelected.atendido"
              placeholder="Select"
              size="mini"
              @change="patchAtendido()"
            >
              <el-option
                v-for="item in atendidoOptiones"
                :key="item.value"
                :label="item.label"
                :value="item.value"/>
            </el-select>
          </div>
          <div>
            <div
              style="
                display: flex;
                align-items: center; justify-content: space-between; align-items: end;
                min-width: 200px;"
              >
              <strong>Comentarios</strong>
              <el-tooltip
                class="item" effect="dark" :content="editComment ? 'Cancelar' : 'Editar'"
                placement="top-start"
              >
                <el-button
                  size="mini"
                  :type="!editComment ? '' : 'danger'"
                  :icon="!editComment ? 'el-icon-edit' : 'el-icon-close'"
                  circle
                  @click="editComment = !editComment"
                />
              </el-tooltip>
            </div>
            <div v-if="editComment" style="display: flex; flex-direction: column;">
              <el-input
                style="margin-top: 5px;"
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 4}"
                v-model="patientSelected.comentarios"
              />
              <el-button
                @click="patchComentarios()"
                size="mini"
                type="success"
                style="margin-top: 5px; align-self: flex-end;"
                >Guardar
              </el-button>
            </div>
            <label
              style="
                margin-top: 5px;
                border: 1px solid rgb(231, 231, 231); padding: 10px 10px 20px 10px;
                border-radius: 8px;
                min-width: 200px; display: flex;
              "
              v-if="editComment === false"
              v-html="patientSelected.comentarios"
            />
          </div>
        </div>
        <el-table
          v-if="citas.length > 0"
          :data="citas"
          stripe
        >
          <el-table-column
            prop="name"
            label="Tipo"
            width="180"/>
          <el-table-column
            prop="type"
            label="Referido a"
            width="180"/>
          <el-table-column
            prop="procedimiento"
            label="Procedimiento"
            width="180"/>
          <el-table-column
            prop="hour"
            label="Hora"
            width="180"/>
          <el-table-column
            prop="date"
            label="Fecha de nacimiento"
            width="180"/>
        </el-table>
        <span
          slot="footer"
          class="dialog-footer"
          style="display:flex;justify-content:space-between;
        ">
          <div>
            <el-button
            type="danger"
            @click="dialogEliminarPaciente = !dialogEliminarPaciente"
            :disabled="citas.length !== 0"
          >
            Eliminar paciente
          </el-button>
          </div>
          <div>
            <el-button
              type="danger"
              @click="dialogPatient = false"
            >
              Cerrar
            </el-button>
            <el-button type="success" @click="crearCitas" :disabled="hour === null">
              <span>Crear cita</span>
            </el-button>
          </div>
      </span>
      <!-- El dialog eliminar paciente -->
    </el-dialog>
    <el-dialog
      title="Eliminar paciente"
      :visible.sync="dialogEliminarPaciente"
      width="50%"
    >
      <span>¿Está seguro que desea eliminar al paciente?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogEliminarPaciente = false">Cancelar</el-button>
        <el-button type="danger" @click="eliminarPaciente">Eliminar</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="Visualización de impresión"
      :visible.sync="dialogImprimir"
      width="80%"
      :close-on-click-modal="false"
      :destroy-on-close="true"
    >
      <div style="padding: 5px 0px 0 0px;">
        <iframe
          :src="pdfUrl" type="application/pdf" width="100%"
          height="600px"
        />
      </div>
    </el-dialog>
    </div>
</template>

<script>
import Calendar from '../../../components/Calendar';
import UserInfo from '../../../components/userInfo';
import PatientAPI from '../../../API/comunidad/patient';
import SpecialtyAPI from '../../../API/globales/especialidades';
import AlianzasAPI from '../../../API/comunidad/alianzas';
import optionsMixin from '../../appointments/mixins/options';

const moment = require('moment');

export default {
  data() {
    // eslint-disable-next-line consistent-return
    const checkAge = (rule, value, callback) => {
      const edad = this.edadPermitida();
      if (!edad) {
        return callback(new Error('Seleccione una fecha'));
      }
      setTimeout(() => {
        if (!Number.isInteger(edad)) {
          callback(new Error('Please input digits'));
        } else if (edad < 18) {
          callback(new Error('La edad debe ser mayor a 18 años'));
        } else if (edad > 120) {
          callback(new Error('La edad debe ser menor a 120 años'));
        } else {
          this.validateName();
          callback();
        }
      }, 1000);
    };
    return {
      pdfUrl: null,
      dialogImprimir: false,
      editComment: false,
      atendidoOptiones: [
        {
          value: true,
          label: 'Si',
        },
        {
          value: false,
          label: 'No',
        },
      ],
      atendidoOpcion: null,
      pacientes: [],
      dialogCalendarJornadaClinica: false,
      dialogEliminarPaciente: false,
      isNew: false,
      isPatient: false,
      dialogIsPatient: false,
      selectedDate: null,
      proyectoId: null,
      sexos: [{ label: 'Femenino', value: 'Femenino' }, { label: 'Masculino', value: 'Masculino' }],
      hour: null,
      date: null,
      padecimientosOptions: [
        {
          value: 'Hipertención',
          label: 'Hipertención',
        },
        {
          value: 'Diabetes',
          label: 'Diabetes',
        },
      ],
      patientInfoObject: {
        diabetes: false,
        arterialHypertension: false,
      },
      referidoOptions: [
        {
          value: true,
          label: 'Fundación +Luz',
        },
        {
          value: false,
          label: 'No referido',
        },
      ],
      especialidades: [],
      errorEdad: null,
      tableData: [],
      FormProject: {
        name: 'Jornada clínica',
        firstName: null,
        lastName: null,
        birthdate: null,
        phone: '',
        sex: '',
        padecimientos: [],
        referido: false,
        especialidad: null,
        patientId: null,
        alianzaId: null,
        comentarios: null,
      },
      rules: {
        municipio: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        firstName: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        lastName: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        birthdate: [
          {
            required: true,
            trigger: 'change',
            validator: checkAge,
          },
        ],
        zipCode: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        sex: [{ required: true, message: '¡Campo requerido!', trigger: 'change' }],
        phone: [{ required: true, message: '¡Campo requerido!', trigger: 'blur' }],
      },
      patientSelected: {},
      dialogPatient: false,
      citas: [],
      citasNuevas: [],
      alianzas: [],
      tipoDeCita: null,
      tipoCitaOptions: [
        {
          value: 1,
          label: 'Especialidad',
        },
        {
          value: 2,
          label: 'Trabajo social',
        },
        {
          value: 3,
          label: 'Consulta general',
        },
      ],
      especialidad: null,
      trabajadorSocial: null,
      trabajadoresSociales: [],
      reservedHours: [],
      showCalendar: true,
      especialidadNombre: null,
      procedimiento: null,
      searchPatient: '',
    };
  },
  mixins: [optionsMixin],
  components: {
    UserInfo,
    Calendar,
  },
  methods: {
    async print() {
      try {
        this.$loading();
        const pdf = await PatientAPI.printPatientComunidad(this.pacientes, this.proyectoId);
        this.pdfUrl = pdf;
        this.dialogImprimir = true;
        this.$loading().close();
      } catch (error) {
        this.$loading().close();
      }
    },
    async patchComentarios() {
      try {
        const request = await PatientAPI.patchComentarioComunidad(
          this.proyectoId,
          this.patientSelected.patientId,
          this.patientSelected.comentarios,
        );
        if (request.status === 204) {
          this.$notify({
            title: 'Éxito',
            message: 'Comentario actualizado correctamente',
            type: 'success',
          });
        } else {
          this.$notify({
            title: 'Error',
            message: 'No se pudo actualizar el comentario',
            type: 'error',
          });
        }
        this.editComment = false;
      } catch (error) {
        // console.log(error);
        this.$notify({
          title: 'Error',
          message: 'No se pudo actualizar el comentario',
          type: 'error',
        });
      }
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    async eliminarPaciente() {
      const request = await PatientAPI.deletePatient(this.patientSelected.patientId);
      if (request.status === 204) {
        this.$notify({
          title: 'Éxito',
          message: 'Paciente eliminado correctamente',
          type: 'success',
        });
        this.dialogEliminarPaciente = false;
        this.dialogPatient = false;
        await this.getPatientList();
      } else {
        this.$notify({
          title: 'Error',
          message: 'No se pudo eliminar el paciente',
          type: 'error',
        });
      }
    },
    selectedHourJornadaClinica(value) {
      this.hour = value;
    },
    selectedDateJornadaClinica(value) {
      this.date = value;
    },
    async submitForm(formName) {
      // eslint-disable-next-line consistent-return
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.register();
        } else {
          return false;
        }
      });
    },
    seguirConRegistro(seguir) {
      if (seguir) {
        if (this.FormProject.firstName != null && this.FormProject.lastName != null) {
          this.dialogIsPatient = false;
          this.isPatient = true;
        }
      } else {
        this.resetFields();
      }
    },
    resetFields() {
      this.isPatient = false;
      this.isNew = false;
      this.dialogCalendarJornadaClinica = false;
      this.patientInfoObject = {
        diabetes: false,
        arterialHypertension: false,
      };
      this.FormProject = {
        name: 'jornadaClinica',
        firstName: null,
        lastName: null,
        birthdate: null,
        phone: '',
        sex: '',
        padecimientos: [],
        referido: false,
        especialidad: null,
        patientId: null,
        comentarios: null,
        zipCode: null,
      };
      this.dialogIsPatient = false;
    },
    async getPatientList() {
      try {
        const request = await PatientAPI.getPatients(this.proyectoId);
        if (request) {
          this.tableData = [];
          request.forEach((element) => {
            this.tableData.push({
              alianza: element?.alianza?.name,
              patientId: element?.patientId,
              firstName: element?.patient?.info[0]?.firstName,
              lastName: element?.patient?.info[0]?.lastName,
              birthdate: element.patient.info[0]?.birthdate,
              phone: element.patient.info[0]?.phone,
              referido: element?.referido ? 'Referido' : 'No referido',
              comentarios: element?.comentarios,
              atendido: element?.atendido ? 'Atendido' : 'No atendido',
            });
          });
          this.pacientes = this.tableData;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async patchAtendido() {
      try {
        const request = await PatientAPI.putPatient(
          this.proyectoId,
          this.patientSelected,
        );
        if (request.status === 204) {
          this.$notify({
            title: 'Éxito',
            message: 'Paciente atendido correctamente',
            type: 'success',
          });
          // this.dialogPatient = false;
          // await this.getPatientList();
        } else {
          this.$notify({
            title: 'Error',
            message: 'No se pudo atender el paciente',
            type: 'error',
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    searchPatientByName() {
      const listPatients = this.pacientes;
      const search = this.searchPatient;

      const result = listPatients.filter((patient) => {
        const name = `${patient?.firstName} ${patient?.lastName}`;
        return name.toLowerCase().includes(search.toLowerCase());
      });
      this.tableData = result;
    },
    handlePadecimientos() {
      // eslint-disable-next-line no-unused-expressions
      this.FormProject.padecimientos.includes('Diabetes') ? this.patientInfoObject.diabetes = true : this.patientInfoObject.diabetes = false;
      // eslint-disable-next-line no-unused-expressions
      this.FormProject.padecimientos.includes('Hipertención') ? this.patientInfoObject.arterialHypertension = true : this.patientInfoObject.arterialHypertension = false;
    },
    async getAllEspecialidades() {
      try {
        const request = await SpecialtyAPI.getEspecialidades();
        if (request) {
          const especialidades = request;
          especialidades.forEach((element) => {
            this.especialidades.push({
              value: element.id,
              label: element.name,
            });
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    // eslint-disable-next-line consistent-return
    async validateName() {
      try {
        const {
          firstName,
          lastName,
        } = this.FormProject;
        if (firstName !== null && lastName !== null) {
          const dateNacimient = this.FormProject.birthdate?.toString();
          if (firstName && lastName && dateNacimient) {
            const exists = await this.axios.get(`patient/specialty/${firstName}/${lastName}/${dateNacimient}`);
            if (exists.data.length > 0) {
              this.FormProject.patientId = exists.data[0].patientId;
              this.isNew = false;
              this.dialogIsPatient = true;
              return '';
            }
            this.isNew = true;
            this.isPatient = false;
            return this.$message({
              message: 'Ciudadano aprobado',
              type: 'success',
            });
          }
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async postPatientComunidad() {
      try {
        const data = this.FormProject;
        const request = await PatientAPI.postPatientComunidad(this.proyectoId, data);
        if (request) {
          this.resetFields();
          await this.getPatientList();
          this.$notify({
            type: 'success',
            title: '¡Éxito!',
            message: '¡Paciente agregado correctamente!',
          });
        }
        this.resetFields();
      } catch (error) {
        // console.log(error);
      }
    },
    async putPatient() {
      try {
        const dataPatientInfo = {
          gender: this.FormProject.sex,
          ...this.patientInfoObject,
        };
        const res = await PatientAPI.putPatientProfile(this.FormProject.patientId, dataPatientInfo);
        if (res) await this.postPatientComunidad();
      } catch (error) {
        // console.log(error);
      }
    },
    async postPatient() {
      try {
        const dataPatientInfo = {
          gender: this.FormProject.sex,
          ...this.patientInfoObject,
          ...this.FormProject,
        };
        const request = await PatientAPI.postPatient(dataPatientInfo);
        if (request) {
          this.FormProject.patientId = request.patient;
          const patientId = request.patient;
          if (patientId) {
            await PatientAPI.postPatientProfile(patientId, dataPatientInfo);
            await this.postPatientComunidad();
          }
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async register() {
      try {
        this.$loading();
        // eslint-disable-next-line no-unused-expressions
        this.isPatient === true ? await this.putPatient() : await this.postPatient();
        this.$loading().close();
      } catch (error) {
        this.$notifyErro(error);
      }
    },
    async changedMonth(args) {
      const FORMAT = 'YYYY-MM-DD';
      let date = null;
      if (args.timestamp) {
        date = moment(new Date(args.timestamp)).format(FORMAT);
      } else {
        date = moment(args).format(FORMAT);
      }
      await this.hightlights(date);
    },
    edadPermitida() {
      // condiciones el ciudadano debe ser mayor a 18
      const hoy = new Date();
      const cumpleanos = new Date(this.FormProject.birthdate);
      let edad = hoy.getFullYear() - cumpleanos.getFullYear();
      const mes = hoy.getMonth() - cumpleanos.getMonth();

      if (mes < 0 || (mes === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
      }
      if (edad < 18) {
        this.errorEdad = 'No cumple con la edad requeridad';
      }
      return edad;
    },
    // abrir modal con la informacion del paciente
    async clickCell(row) {
      this.citas = [];
      const patientComunidad = await PatientAPI.getPatientComunidad(this.proyectoId, row.patientId);
      await this.getCitas(row.patientId);

      const patientInfo = {
        ...row,
        ...patientComunidad,
      };
      this.patientSelected = await patientInfo;
      this.dialogPatient = true;
    },
    async getCitas(patientId) {
      try {
        const request = await PatientAPI.getPatientCitas(patientId);
        if (request) {
          const claves = Object.keys(request);
          for (let i = 0; i < claves.length; i++) {
            const key = claves[i];
            const cita = request[key];

            // eslint-disable-next-line consistent-return
            const tipoCita = () => {
              if (cita.speciality) {
                return 'Especialidad';
              }
              if (cita.user) {
                return 'Trabajo social';
              }
              return 'Consulta general';
            };

            const typeCita = () => {
              if (cita.speciality) {
                return cita.speciality.name;
              }
              if (cita.user) {
                return `${cita.user.firstName} ${cita.user.lastName}`;
              }
              return 'Consulta general';
            };

            const tipo = tipoCita();
            const type = typeCita();

            const citaObject = {
              id: cita.id,
              name: tipo,
              date: cita.date,
              hour: cita.time,
              type,
              patientId: cita.patientId,
              procedimiento: cita.procedure || 'TRABAJO SOCIAL',
              referido: (cita?.referido ? `SI, a ${cita?.referido}` : 'NO') || 'NO',
              status: cita.status,
            };

            this.citas.push(citaObject);
          }
        }
      } catch (error) {
        // console.log(error);
      }
    },
    clearAll() {
      this.patientSelected = {};
      this.especialidad = null;
      this.procedimiento = null;
      this.tipoDeCita = null;
      this.trabajadorSocial = null;
      this.hour = null;
      this.showCalendar = false;
      this.citasNuevas = [];
      this.date = null;
    },
    async getAllTSusers() {
      try {
        const request = await this.axios('patient/subsecuente/usersSocial');
        const { data } = request;
        if (request) {
          // this.cards.push(...data);
          data.forEach((element) => {
            this.trabajadoresSociales.push({
              label: `${element.firstName} ${element.lastName}`,
              value: element.id,
            });
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async getReservedHoursTS() {
      try {
        this.loading = true;
        this.reservedHours = [];
        if (this.date) {
          const response = await this.axios(`patient/appointment/social/reserved-schedules/${this.trabajadorSocial}?date=${this.date}`);
          const { data } = response;
          if (Array.isArray(data) && data.length > 0) {
            this.reservedHours = data.map((x) => ({
              ...x,
              time: x.time.substring(0, 5),
            }));
          }
        }
        this.loading = false;
      } catch (error) {
        this.$errorNotify(error);
        this.loading = false;
      }
    },
    async getReservedHoursConsultaGeneral() {
      try {
        this.$loading();
        if (this.date) {
          this.reservedHours = [];
          const response = await this.axios(`patient/subsecuente/md/reserved-schedules?date=${this.date}`);
          const { data } = response;
          if (Array.isArray(data)) this.reservedHours = data;
        }
        this.$loading().close();
      } catch (error) {
        this.$loading().close();
        this.$errorNotify(error);
      }
    },
    async getReservedHours() {
      try {
        this.$loading();
        if (this.date) {
          this.reservedHours = [];
          const { especialidad } = this;
          const response = await this.axios(`patient/subsecuente/esp/reserved-schedules/${especialidad}?date=${this.date}`);
          const { data } = response;
          if (Array.isArray(data)) this.reservedHours = data;
        }
        this.$loading().close();
      } catch (error) {
        this.$loading().close();
        this.$errorNotify(error);
      }
    },
    mostrarCalendario() {
      this.showCalendar = true;
    },
    mostrarProcedimientos() {
      this.procedimiento = null;
      this.especialidadNombre = this.especialidades.find(
        (item) => item.value === this.especialidad,
      ).label;
    },
    async crearCitas() {
      try {
        await this.agregandoCitaNueva();
        const pacienteId = this.patientSelected.patientId;
        const request = await this.axios.post(`patient/subsecuente/multiple/${pacienteId}`, this.citasNuevas);
        if (request) {
          await this.actualizarReferido(pacienteId);
          this.citasNuevas = [];
          this.$notify({
            title: 'Éxito',
            message: 'Citas creadas correctamente',
            type: 'success',
          });
          this.dialogCalendar = false;
          this.clearAll();
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async actualizarReferido(pacienteId) {
      try {
        const request = await this.axios.put(`patient/patientComunidad/referido/${pacienteId}`, {
          referido: true,
        });
        if (request) {
          this.$notify({
            title: 'Éxito',
            message: 'Referido actualizado correctamente',
            type: 'success',
          });
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async agregandoCitaNueva() {
      this.dialogCalendar = true;
      if (this.tipoDeCita === 2) {
        const trabajador = this.trabajadoresSociales.find(
          (item) => item.value === this.trabajadorSocial,
        );
        const citaObject = {
          name: 'TRABAJO SOCIAL',
          date: this.date,
          hour: this.hour,
          type: trabajador.label,
          patientId: this.patientSelected.patientId,
          userId: this.trabajadorSocial,
          place: this.FormProject.place,
          status: '',
        };
        await this.citasNuevas.push(citaObject);
      }
      if (this.tipoDeCita === 1) {
        const citaObject = {
          name: 'ESPECIALIDAD',
          procedimiento: this.procedimiento,
          place: this.FormProject.place,
          date: this.date,
          hour: this.hour,
          type: this.especialidad,
          patientId: this.patientSelected.patientId,
          status: '',
        };
        await this.citasNuevas.push(citaObject);
      }
      if (this.tipoDeCita === 3) {
        const citaObject = {
          name: 'CONSULTA GENERAL',
          date: this.date,
          hour: this.hour,
          type: 'PRIMERA CITA',
          subject: 'Médico',
          patientId: this.patientSelected.patientId,
          status: '',
        };
        await this.citasNuevas.push(citaObject);
      }
    },
  },
  async created() {
    this.$loading();
    this.proyectoId = this.$route.params.proyectoId;
    await this.getAllTSusers();
    await this.getPatientList();
    await this.getAllEspecialidades();
    const alianzas = await AlianzasAPI.getAlianzas();
    if (alianzas) this.alianzas.push(...alianzas);
    this.$loading().close();
  },
  watch: {
    async selectedDate(val) {
      if (val) {
        const date = moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
        this.FormProject.date = date;
      }
    },
    searchPatient(val) {
      if (val.length >= 1 && val) {
        this.searchPatientByName();
      } else if (val.length === 0) {
        this.tableData = this.pacientes;
      }
    },
    date(val) {
      if (val) {
        if (this.tipoDeCita === 2 && this.trabajadorSocial !== null) this.getReservedHoursTS();
        if (this.tipoDeCita === 1 && this.especialidad !== null) this.getReservedHours();
        if (this.tipoDeCita === 3) this.getReservedHoursConsultaGeneral();
      }
    },
    especialidad(val) {
      if (val) {
        this.getReservedHours();
      }
    },
    trabajadorSocial(val) {
      if (val) {
        this.getReservedHoursTS();
      }
    },
    consultaGeneral(val) {
      if (val) {
        this.getReservedHoursConsultaGeneral();
      }
    },
    tipoDeCita(val) {
      if (val) {
        if (val === 1) {
          this.trabajadorSocial = null;
        }
        if (val === 2) {
          this.especialidad = null;
        }
        if (val === 3) {
          this.especialidad = null;
          this.trabajadorSocial = null;
        }
      }
    },
    // eslint-disable-next-line func-names
    'FormProject.firstName': async function (val) {
      if (val.length >= 5 && val != null) await this.validateName();
    },
    // eslint-disable-next-line func-names
    'FormProject.lastName': async function (val) {
      if (val.length >= 5 && val != null) await this.validateName();
    },
  },
};
</script>

<style lang="scss" scoped>
.title-consultorioMovil {
  margin: 0px;
  color: #8F70C7;
}
h3 {
  margin: 0px;
  color: #3e3e3e;
}
.button-agendar {
  background-color: #8F70C7;
  // border: none;
  color: #fff;
}
.row-consultorioMovil {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}
#registrar-button {
  background-color: #8F70C7;
  border: none;
  color: #fff;
  font-size: 16px;
  min-width: 210px;
}
</style>

<style lang="scss">
.el-row {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .el-row {
    width: 200px;
  }
}
.container-datosDeRegistro {
  padding: 20px;
}
.el-form--label-top .el-form-item__label {
  padding: 0px;
}/* width */
.el-form-item__label {
  line-height: 30px;
  font-weight: bold;
  color: #8F70C7;
  font-size: 16px;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(157, 108, 229);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(123, 59, 218, 0.855);
  border-radius: 12px;
}
.option-jornadaclinica {
  margin-top: 15px;
  display: flex;
  justify-content: end;
}
</style>
