import http from '../index';

const BASEURI = 'patient/alianza';

const AlianzasAPI = {
  // eslint-disable-next-line consistent-return
  getAlianzas: async () => {
    try {
      const request = await http.get(BASEURI);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
  // eslint-disable-next-line consistent-return
  postAlianza: async (data) => {
    try {
      const request = await http.post(BASEURI, data);
      if (request) {
        return request.data;
      }
    } catch (error) {
      this.$errorNotify(error);
    }
  },
};

export default AlianzasAPI;
